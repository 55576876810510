












































import { Component, Vue } from 'vue-property-decorator'
import CForm from '@/components/base/Form.vue'
import axios from 'axios'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import router from '../router/index'
import LIVR from 'livr'
import FieldError from '@/components/base/FieldError.vue'
// For more options see below
Vue.use(VueReCaptcha, { siteKey: '6LdFN8AbAAAAAKULFVH_YRDRUyMlcfdn3m1wFzq1' })

@Component({ components: { CForm, FieldError } })
export default class FreeDemo extends Vue {
  private rules = {
    firstname: ['required', 'trim'],
    lastname: ['required', 'trim'],
    companyname: ['string', 'trim'],
    email: ['required', 'email', 'trim'],
    phone: { min_length: 8 },
    country: { min_length: 2 },
    zipcode: { min_length: 2 },
    terms: ['required', 'acceptedTerms'],
  }
  private terms: field = { key: 'terms', type: 'checkbox', error: '' }
  private form: IForm = {
    ctaTitle: this.$t('freeDemo.button') as string,
    fields: [
      {
        key: 'terms',
        type: 'checkbox',
        error: '',
        text: this.$t('fields.terms') as string,
      },
      {
        key: 'firstname',
        type: 'text',
        placeholder: 'firstName',
        width: 'half',
        name: 'given-name',
        error: '',
      },
      {
        key: 'lastname',
        type: 'text',
        placeholder: 'lastName',
        width: 'half',
        name: 'family-name',
        error: '',
      },
      {
        key: 'companyname',
        type: 'text',
        placeholder: 'companyName',
        name: 'organization',
        error: '',
      },
      {
        key: 'email',
        type: 'email',
        placeholder: 'email',
        width: 'half',
        name: 'email',
        error: '',
      },
      {
        key: 'phone',
        type: 'tel',
        placeholder: 'phone',
        width: 'half',
        name: 'tel',
        error: '',
      },
      {
        key: 'country',
        type: 'text',
        placeholder: 'country',
        width: 'quarter',
        name: 'country-name',
        error: '',
      },
      {
        key: 'zipcode',
        type: 'text',
        placeholder: 'zipcode',
        width: 'quarter',
        name: 'postal-code',
        error: '',
      },
    ],
  }

  get checkboxes(): field[] {
    return this.form.fields.filter((field) => field.type === 'checkbox')
  }

  private async formHandler(ev: Event) {
    if (!ev || ev.target == null) return
    const eventTarget = ev.target as HTMLFormElement
    // (optional) Wait until recaptcha has been loaded.
    await this.$recaptchaLoaded()

    // Execute reCAPTCHA with action "sendform"
    // Notice: action must be alphanumeric and must *not* include any minus characters
    //         https://github.com/google/recaptcha/issues/394#issuecomment-650085324
    const token = await this.$recaptcha('sendform')
    const autoFields = this.form.fields.reduce((obj, field) => {
      return {
        ...obj,
        [field.key]: eventTarget[field.key].value,
      }
    }, {})

    const dto = {
      ...autoFields,
      token,
      terms: eventTarget['terms'].checked,
      deliveries: eventTarget['deliveries'].value,
    }
    const isFormGood = this.validateForm(dto)

    if (!isFormGood) return

    try {
      // const { status } = { status: 200 }
      const { status } = await axios.post<any>('api/mail.php', dto)
      if (status === 200) {
        router.push({ name: 'FreeDemoThx'})
      }

      this.$recaptchaInstance.hideBadge()
    } catch (error) {
      console.error('error:', error)
    }
  }
  validateForm(userData: Record<string, string>): boolean {
    const validator = new LIVR.Validator(this.rules)

    validator.registerRules({
      acceptedTerms() {
        return (value: boolean) => {
          if (value) return
          return 'ACCEPT_TERMS'
        }
      },
    })
    const validData: boolean | Record<string, string> = validator.validate(
      userData
    )
  
    const errors = validator.getErrors() as Record<string, string>

    this.form.fields = this.form.fields.map((field) => {
      field.error = errors && field.key in errors ? errors[field.key] : ''
      return field
    })

    return validData ? true : false // validator.validate() returns the missing fields otherwise
  }
}
