






































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import CInput from '@/components/base/Input.vue'

@Component({ components: { CInput } })
export default class Form extends Vue {
  @Prop({ required: true })
  private readonly form!: IForm

  @Emit()
  submitForm(ev: Event): Event {
    // try {
    //   this.success = this.$t('freeDemo.success') as string
    // } catch (error) {
    //   console.error('error:', error)
    // }
    return ev
  }

  private success = ''

  get fieldsWithoutCheckboxes(): field[] {
    return this.form.fields.filter((field) => field.type !== 'checkbox')
  }

  get ctaBgColor(): string {
    return this.form.ctaBgColor ?? 'bg-primary'
  }

  get ctaTextColor(): string {
    return this.form.ctaTextColor ?? 'text-white'
  }

  get ctaTitle(): string {
    return this.form.ctaTitle ?? 'submit'
  }
}
