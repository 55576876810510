var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"data-test":"form-item","autocomplete":"on"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('div',{attrs:{"data-test":"form-title"}},[_vm._v(_vm._s(_vm.form.title))]),_c('div',{staticClass:"grid lg:grid-cols-12 gap-x-14 gap-y-24",attrs:{"data-test":"form-title"}},[_vm._l((_vm.fieldsWithoutCheckboxes),function(field){return _c('div',{key:field.key,staticClass:"w-full",class:[
        {
          'col-span-6': field.width === 'half',
          'col-span-4': field.width === 'third',
          'col-span-3': field.width === 'quarter',
          'col-span-12':
            field.width !== 'third' &&
            field.width !== 'half' &&
            field.width !== 'quarter',
        } ]},[_c('CInput',{staticClass:"w-full",attrs:{"field":field,"data-test":"form-field-items"}})],1)}),_vm._t("default")],2),_c('button',{staticClass:"rounded px-16 py-8 mt-16",class:[_vm.ctaBgColor, _vm.ctaTextColor],attrs:{"type":"submit","data-test":"form-cta"}},[_vm._v(" "+_vm._s(_vm.ctaTitle)+" ")]),(_vm.success)?_c('div',{staticClass:"text-primary",attrs:{"data-test":"form-success"}},[_vm._v(" "+_vm._s(_vm.success)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }